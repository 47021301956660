import axios from 'axios';

const API_URL:string = 'https://chat.youevolve.in/api/v1'; 
//const API_URL:string =  'http://localhost:8080/api/v1'; 

//creating the axios instance
const axiosInstance = axios.create({
    baseURL: API_URL,
});


// Function to retrieve the token from local storage
const getToken = () => localStorage.getItem('accessToken');

// Request interceptor to add token to headers
axiosInstance.interceptors.request.use(
    config => {
        debugger;
        const token = getToken();
        if (token) {
            config.headers['X-Authorization'] = token;
        }
        return config;
    },
    error => Promise.reject(error)
);

// axiosInstance.interceptors.response.use(
//     response => response,
//     async error => {
//         const originalRequest = error.config;
//         if (error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             // Handle token refresh logic here
//             // e.g., const newToken = await refreshToken();
//             // localStorage.setItem('accessToken', newToken);
//             // return axiosInstance(originalRequest);
//         }
//         return Promise.reject(error);
//     }
// );


export default axiosInstance;