import { call, put, takeLatest } from 'redux-saga/effects';
import { loginRequest, loginSuccess, loginFailure,logout,logoutFailure} from './authSlice';
import { login as loginAPI,logout as logoutAPI } from '../../api/auth';
import Swal from 'sweetalert2';

 function* handleLogin(action: ReturnType<typeof loginRequest>) {
  try {
    const { data } = yield call(loginAPI, action.payload.reqData);
    localStorage.setItem('user', JSON.stringify(data.data.user));
    localStorage.setItem('accessToken', data.data.accessToken);
    localStorage.setItem('refreshToken', data.data.refreshToken);
    yield put(loginSuccess(data.data));
    debugger;
    action.payload.navigate('/home');
  } catch (error: any) {
    debugger;
    yield Swal.fire({
      title: "Error",
      text: error.response.data.message??"Something went wrong! Try after sometime",
      icon: "error",
      position:"top"
    });
    yield put(loginFailure(error.response.data.message));
  }
}

function* handleLogout() {
  try {
    debugger;
    const {data} = yield call(logoutAPI);
    localStorage.removeItem('user'); 
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    yield put(logout(data));
  } catch (error: any) {
    yield put(logoutFailure(error.message));
  }
}


export default function* authSaga() {
  yield takeLatest(loginRequest.type, handleLogin);
  yield takeLatest(logout.type,handleLogout);
}
