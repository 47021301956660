import React, { useState } from "react";
import "./header.css";
import { NavLink } from "react-router-dom";

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <>
      <div className="header-container">
        <div className="d-flex flex-wrap  align-items-center text-white fs-6 bg-secondary rounded-bottom p-3">
          {/* Profile Icon */}
          <div className="d-flex align-items-center me-auto">
            <i className="profile-circle  bi bi-person-circle"></i>
          </div>

          {/* Menu Items */}
          <div className="menu-wrapper">
            <div
              className={`allHeader d-flex flex-wrap gap-4 ${
                menuOpen ? "menu-open" : "d-none d-md-flex"
              }`}
            >
              <div className=" mt-2">
                <strong>
                  <NavLink className={"home"} to="/home">
                    Home
                  </NavLink>
                  {/* <a className="home " href="/new">
                    Home
                  </a> */}
                </strong>
              </div>
              <div className="mt-2">
                <strong>
                  <a className="events" href="/new">
                    Events
                  </a>
                </strong>
              </div>
              <div className=" mt-2">
                <strong>
                  <NavLink
                    className={"mentalDisorders"}
                    to="/mentalDisorders"
                  >
                    Mental Disorders
                  </NavLink>
                  {/* <a className="mentalDisorders" href="/new">
                    Mental Disorders
                  </a>{" "} */}
                </strong>
              </div>
              <div className=" mt-2">
                <strong>
                  {/* <a className="leaderboard" href="/new">
                    Leaderboard
                  </a> */}
                  <NavLink className={"leaderboard"} to="/LeaderBoardScreennew">
                    LeaderBoard
                  </NavLink>
                </strong>
              </div>
              <div className=" mt-2">
                <strong>
                  <a className="merchandises" href="/new">
                    Merchandises
                  </a>
                </strong>
              </div>
              <div className=" mt-2">
                <strong>
                  <a className="counsellorsAndTherapists" href="/new">
                    Counsellors And Therapists
                  </a>
                </strong>
              </div>
              <div className="mt-2">
                <strong>
                  <a className="institutePortal" href="/new">
                    Institute Portal
                  </a>
                </strong>
              </div>
              <div className=" mt-2">
                <strong>
                  <a className="guardianPortal" href="/new">
                    Guardian Portal
                  </a>
                </strong>
              </div>
            </div>
          </div>

          {/* Icons */}
          <div className="d-flex align-items-center ms-auto">
            <i className="bookmark_new bi bi-bookmark-fill me-3"></i>
            <i className="search_new  bi bi-search me-3"></i>

            {/* Menu Toggle Button (Visible on smaller screens) */}
            <div className="d-md-none">
              <button className="btn btnlight mb-3 " onClick={toggleMenu}>
                <i className="fs-2 fw-1 bi bi-list"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
