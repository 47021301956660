// import React from 'react';
// import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import Login from '../pages/Login';
// import Signup from '../pages/Signup';
// import Home from '../pages/Home';
// import PrivateRoute from './PrivateRoute';

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <PrivateRoute><Home /></PrivateRoute>,
//   },
//   {
//     path: '/login',
//     element: <Login />,
//   },
//   {
//     path: '/signup',
//     element: <Signup />,
//   },
//   {
//     path: '*',
//     element: <Login />, // Default route or 404 handler
//   },
// ]);

// const AppRouter: React.FC = () => <RouterProvider router={router} />;

// export default AppRouter;

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Homeold from "../pages/Homeold";
import NotFound from "../pages/NotFound";
import { useSelector } from "react-redux";
import { RootState } from "../store/index";
import SignupOTP from "../pages/SignupOTP";
import Registration from "../pages/Registration";
import MentalDisorder from "../pages/MentalDisorder";
import LeaderBoard from "../pages/LeaderBoard";
import NationalStanding from "../pages/NationalStanding";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Home from "../pages/Home/Home";
import ZonalStanding from "../pages/LeaderBoardScreen/ZonalStanding/ZonalStanding";
import LeaderBoardScreennew from "../pages/LeaderBoardScreen/LeaderBoardScreennew";
import MentalDisorders from "../pages/mentalDisorders/MentalDisorders";
import NationalStandingNew from "../pages/LeaderBoardScreen/NationalStandingNew/NationalStandingNew";
import InstitutionalStanding from "../pages/LeaderBoardScreen/InstitutionalStanding/InstitutionalStanding";
const App: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/mentalDisorder" element={<MentalDisorder />} />
        <Route path="/mentalDisorders" element={<MentalDisorders />} />
        <Route path="/leaderBoard" element={<LeaderBoard />} />
        <Route path="/zonalStanding" element={<ZonalStanding />} />
        <Route
          path="/nationalstanding"
          element={<NationalStandingNew />}
        ></Route>
        {/* <Route path="/nationalStanding" element={<NationalStanding />} /> */}
        <Route
          path="/LeaderBoardScreennew"
          element={<LeaderBoardScreennew />}
        />
        <Route
          path="/institutionalStanding"
          element={<InstitutionalStanding />}
        />
        <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
        {/* <Route path="/home" element={  <Home />}  /> */}
        <Route
          path="/home"
          element={user ? <Home /> : <Navigate to="/login" />}
        />
        <Route path="/header" element={<Header />}></Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/footer" element={<Footer />}></Route>
        {/* <Route path="/homescreen" element={<HomeScreen />}></Route> */}
      </Routes>
    </Router>
  );
};

export default App;
