import React from 'react';
const Loader:React.FC=()=>{

    return(
        <div className="loader-backdrop">
            <div className="loader"></div>
        </div>
    )

}

export default Loader;