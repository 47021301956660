import React, {
  useState,
  useEffect,
  Fragment,
  MenuHTMLAttributes,
} from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import img1 from "../../assets/images/womanInDepression.png";
import img2 from "../../assets/images/womanInThoughtfulPose.png";
import img3 from "../../assets/images/womanSittingAndPointing.png";
import "./MentalDisorders.css";
import { getMentalHealthIssuesAndItsTypes } from "../../api/healthIssue";
import { HealthIssueTypes, Mentadisorder, MentadisorderObj, SelectOption } from "../../store/interface";
import { AxiosError } from "axios";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";
import Select from "../../components/select/Select";

const MentalDisorders: React.FC = () => {
  const initialMentalDisorderObj: MentadisorderObj = {
    id: 0,
    name: "",
    image: "",
    description: "",
    symptoms: [],
    treatmentOptions: [],
  };

  const imgArr = [img1, img2, img3];
  const [mentaldisorderData, setMentaldisorderData1] = useState<Mentadisorder[]>([]);
  const [loader,setLoader]=useState<boolean>(false);
  const [mentadisorderObj, setMentadisorderObj] = useState<MentadisorderObj>(initialMentalDisorderObj);
  const [errors, setErrors] = useState<string | null>(null);
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    const getData = async () => {
      try {
        debugger;
        setLoader(true);
        const response = await getMentalHealthIssuesAndItsTypes();
        const _data= response.data.map((data)=>{
          if(data.healthIssueTypes && data.healthIssueTypes?.length>0){
            data.types=[];
            data.types?.push({ label: 'Select Types', value: '' });
            const typeOptions =data.healthIssueTypes?.map((item)=>{
              const _option:SelectOption={label:item.typeName, value: item.id.toString() };
              return _option;
            });
            debugger;
            data.types?.push(...typeOptions);
            console.log(data.types);
          }
          else{
            data.types=[]
          }
          return data;
        });
        setMentaldisorderData1(_data);
        setLoader(false);
      } catch (error) {
        debugger;
        setLoader(false);
        let _errorMsg: string = "";
        if (error instanceof AxiosError) {
          setErrors(error.message);
          _errorMsg = error.message;
        } else {
          setErrors("An unknown error occurred");
          _errorMsg = "Something went wrong! Try after sometime";
        }

        Swal.fire({
          title: "Error",
          text: _errorMsg,
          icon: "error",
          position: "top",
        });
      }
    };

    getData();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = (id: number): void => {
    const disorder: Mentadisorder = mentaldisorderData.find(
      (x) => x.id === id
    ) ?? {
      id: 0,
      name: "",
      description: "",
      symptoms: "",
      treatmentOptions: "",
      image: "",
    };
    const _disorderObj: MentadisorderObj = initialMentalDisorderObj;
    _disorderObj.id = disorder.id;
    _disorderObj.name = disorder.name;
    _disorderObj.description = disorder.description;
    _disorderObj.symptoms = disorder.symptoms
      ? splitText(disorder.symptoms)
      : [];
    _disorderObj.treatmentOptions = disorder.treatmentOptions
      ? splitText(disorder.treatmentOptions)
      : [];
    setMentadisorderObj(_disorderObj);
    setShow(true);
  };

  const splitText = (inputText: string) => {
    const result: string[] = [];
    let currentSection: string = "";
    let openParentheses: number = 0;

    for (let i = 0; i < inputText.length; i++) {
      const char = inputText[i];

      if (char === "(") {
        openParentheses++;
      }

      if (char === ")") {
        openParentheses--;
      }

      if (char === "," && openParentheses === 0) {
        result.push(currentSection.trim());
        currentSection = "";
      } else {
        currentSection += char;
      }
    }

    if (currentSection) {
      result.push(currentSection.trim());
    }

    return result;
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>, id: any) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    if(selectedValue){

      const disorder: Mentadisorder = mentaldisorderData.find(
        (x) => x.id === id
      ) ?? {
        id: 0,
        name: "",
        description: "",
        symptoms: "",
        treatmentOptions: "",
        image: "",
      };
  
      const _type:HealthIssueTypes=disorder.healthIssueTypes?.find(x=>x.id===parseInt(selectedValue))
      ??{
        id: 0,
        mentalHealthIssueId: 0,
        typeName: "",
        typeDescription: "",
        symptoms: [],
        treatment: []
      };
  
      const _disorderObj: MentadisorderObj = initialMentalDisorderObj;
      _disorderObj.id = parseInt(selectedValue);
      _disorderObj.name = _type.typeName;
      _disorderObj.description = _type.typeDescription;
      _disorderObj.symptoms = _type.symptoms;
        
      _disorderObj.treatmentOptions =_type.treatment;
       
      setMentadisorderObj(_disorderObj);
      setShow(true);

    }

  };

  return (
    <div className="disorder-bg-color">
        {loader&& <Loader/>}
      <div className="disorder-container">
        <Header />
        <div className="container-fluid">
          <div className="app-header-center">
            <h2>Mental Disorders</h2>
          </div>
          <div>
            <div className="row">
              {mentaldisorderData.map((disorder: Mentadisorder) => (
                <div
                  className="col-xs-12 col-sm-6 col-md-4 col-lg-3 card-default-padding"
                  key={disorder.id}
                >
                  {/* <div>
                                    <div>{disorder.mentaldisorderName}</div>
                                    <button onClick={() => openModal(disorder)}>KNOW MORE</button>
                                </div>
                                <img src={disorder.metaldisorderImage} alt={disorder.mentaldisorderName} /> */}

                  <div className="card glass text-center mb-3">
                    <div className="card-img-container">
                      {disorder.image ? (
                        <img
                          src={disorder.image}
                          className="card-img-top"
                          alt={disorder.name}
                        />
                      ) : (
                        <img
                          src={
                            imgArr[Math.floor(Math.random() * imgArr.length)]
                          }
                          className="card-img-top"
                          alt={disorder.name}
                        />
                      )}
                    </div>
                    <div className="card-body">
                      <div className="mental-disorder-title-div"><h5 className="card-title" title={disorder.name} >{disorder.name}</h5></div>
                      {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                     <div className="row mental-disorder-button-div">
                      {
                        disorder.types &&disorder.types.length>0?
                        <Fragment>
                          <div className="col-xs-12 col-sm-12 col-md-12  col-lg-12 col-xl-6 mb-3"><button 
                            type="button"
                            onClick={() => handleShow(disorder.id)}
                          >
                            Know more
                          </button></div>
                          <Select  onChange={handleSelectChange} extraData={disorder.id} className="col-xs-12 col-sm-12 col-md-12  col-lg-12 col-xl-6 mb-3" showLabel={false} options={disorder.types?disorder.types:[]}/>
                        </Fragment>:
                        <Fragment>
                        <div className="mb-3"><button 
                          type="button"
                          onClick={() => handleShow(disorder.id)}
                        >
                          Know more
                        </button></div>
                        
                      </Fragment>
                      }
                     
                     </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* {modalVisible && currentDisorder && ( */}
        {show && (
          <div className="modalOverlay" onClick={handleClose}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
              <button className="modalCloseButton" onClick={handleClose}>
                X
              </button>
              <br />
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1 style={{ color: "#FFF", padding: "15px 0px 0px 20px" }}>
                  {mentadisorderObj.name}
                </h1>
              </div>
              <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 card-default-padding">
                  <div className="card disorder-card-bg-color glass text-center mb-3">
                    <div className="card-body">
                      <h3 className="card-title">Description</h3>
                      <div className="modal-div"></div>
                      <p className="card-text">{mentadisorderObj.description}</p>
                      <div style={{ paddingTop: "20px" }}></div>
                    </div>
                    
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 card-default-padding">
                  <div className="card disorder-card-bg-color glass text-center mb-3">
                    <div className="card-body">
                      <h3 className="card-title">Symptoms</h3>
                        <div className="modal-div"></div>
                        {mentadisorderObj.symptoms??[].length > 0 ? (
                        <ul>
                         {
                           mentadisorderObj.symptoms?.map((item: string) => 
                            <li>{item}</li>
                          )}
                        </ul>
                        ) : (
                        <p className="card-text">Will upate soon!</p>
                        )}
                        <div style={{ paddingTop: "20px" }}></div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 card-default-padding">
                  <div className="card disorder-card-bg-color glass text-center mb-3">
                    <div className="card-body">
                      <h3 className="card-title">Treatment Options</h3>
                      <div className="modal-div"></div>
                        {mentadisorderObj.treatmentOptions??[].length > 0 ? (
                          <ul>
                            {
                               mentadisorderObj.treatmentOptions?.map((item: string) => (
                                <li className="card-text">{item}</li>
                              ))
                            }
                          </ul>
                        ) : (
                        <p className="card-text">Will upate soon!</p>
                        )}
                        <div style={{ paddingTop: "20px" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default MentalDisorders;
